import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const RosaceaUndCouperose = ({ data, location, ...props }) => {
	return (
		<Layout
			location={location}
			title="Rosacea und Couperose"
			noSlider
			{...props}
		>
			<p>
				Eine Rosacea ist gekennzeichnet durch gerötete Areale des
				Gesichtes mit zum Teil entzündlichen Herden und einer
				Erweiterung der kleinsten oberflächlichen Hautgefäße, der
				sogenannten Couperose.
			</p>
			<p>
				Die Ursache ist noch nicht endgültig schlüssig geklärt. Die
				Wissenschaft geht zurzeit von einer immunologischen Erkrankung
				aus.
			</p>
			<p>
				Eine Heilung ist zurzeit nicht möglich. Doch gibt es
				medikamentöse Therapien in Form von Cremes und Tabletten, mit
				denen wir eine hervorragende Besserung erreichen.
			</p>
			<p>
				Ergänzend bieten wir Ihnen Begleitbehandlungen mit medizinischen
				Peelings, Mikroneedling, Laser und Radiofrequenz um kosmetisch
				unerwünschte Stadien und begleitend auftretende
				Gefäßerweiterungen wirklich erfolgreich verbessern zu können.
			</p>

			<Questions title="Rosacea und Couperose" prefix="um" />
		</Layout>
	);
};

export default RosaceaUndCouperose;
